import { Completion } from '@codemirror/autocomplete';
import { WorkspaceT } from '@/pages/workgroup/type';
import { reqGraphSchema } from '@/utils/graphEditor/useMetaHook';
import { Schema } from '@tigergraph/tools-ui/graph/type';

export class SchemaCompletion {
  attrCompletions: Map<string, Completion[]> = new Map();
  allAttrCompletions: Completion[] = [];
  vertexEdgeCompletions: Completion[] = [];
  vertexCompletions: Completion[] = [];
  edgeCompletions: Completion[] = [];

  constructor(schema: Schema) {
    for (const vertex of schema.VertexTypes) {
      this.vertexCompletions.push({ label: vertex.Name, type: 'vertex', boost: 1.5 });
      const attrCompletions = [];
      for (const attr of vertex.Attributes) {
        attrCompletions.push({ label: attr.AttributeName, type: 'field' });
      }
      attrCompletions.push({ label: vertex.PrimaryId.AttributeName, type: 'field' });
      this.attrCompletions.set(vertex.Name, attrCompletions);
      this.allAttrCompletions.push(...attrCompletions);
    }
    for (const edge of schema.EdgeTypes) {
      this.edgeCompletions.push({ label: edge.Name, type: 'edge', boost: 1.5 });
      const attrCompletions = [];
      for (const attr of edge.Attributes) {
        attrCompletions.push({ label: attr.AttributeName, type: 'field' });
      }
      this.attrCompletions.set(edge.Name, attrCompletions);
      this.allAttrCompletions.push(...attrCompletions);
    }
  }

  getAllAttrCompletions(): Completion[] {
    return this.allAttrCompletions;
  }

  getAttrCompletions(vertexEdges: string[]): Completion[] {
    const completions: Completion[] = [];
    for (const vertexEdge of vertexEdges) {
      if (this.attrCompletions.has(vertexEdge)) {
        completions.push(...(this.attrCompletions.get(vertexEdge) || []));
      }
    }
    return completions;
  }

  getVertexCompletions(): Completion[] {
    return this.vertexCompletions;
  }

  getEdgeCompletions(): Completion[] {
    return this.edgeCompletions;
  }

  getVertexEdgeCompletions(): Completion[] {
    return [...this.vertexCompletions, ...this.edgeCompletions];
  }
}

const emptySchema: Schema = {
  VertexTypes: [],
  EdgeTypes: [],
  GraphName: '',
};

class SchemaCache {
  private schemaCompletions: Map<string, SchemaCompletion> = new Map();
  private currentWorkspace: WorkspaceT | undefined;
  private graphNames: string[] = [];

  constructor() {}

  setWorkSpaceInfo(workspace: WorkspaceT | undefined, graphNames: string[]) {
    this.currentWorkspace = workspace;
    this.graphNames = graphNames;
  }

  getGraphNameCompletions(): Completion[] {
    return this.graphNames.map((graphName) => ({ label: graphName, type: 'graph', boost: 1.5 }));
  }

  async getSchemaCompletion(graphName: string): Promise<SchemaCompletion | undefined> {
    if (!this.currentWorkspace || !this.graphNames.includes(graphName)) {
      return undefined;
    }

    if (!this.schemaCompletions.has(graphName)) {
      // avoid sending duplicate requests
      this.schemaCompletions.set(graphName, new SchemaCompletion(emptySchema));
      const schema: Schema = await this.fetchSchema(graphName);
      this.schemaCompletions.set(graphName, new SchemaCompletion(schema));
    }
    return this.schemaCompletions.get(graphName);
  }

  async fetchSchema(graphName: string) {
    const response: any = await reqGraphSchema(graphName, {
      baseURL: `https://${this.currentWorkspace!.nginx_host}`,
      version: this.currentWorkspace!.tg_version,
    });
    return response.results;
  }
}

export const schemaCache = new SchemaCache();
