import { useWorkspaceContext } from '@/contexts/workspaceContext';
import { Result } from '@/lib/type';
import { useQueryCPs, useQueryPublicCPs } from '@/pages/admin/settings/cloud_provider/hook';
import { CloudProviderT } from '@/pages/admin/settings/cloud_provider/types';
import { isCPDisabled } from '@/pages/admin/settings/cloud_provider/util';
import {
  deleteDatabase,
  deleteWorkSpace,
  getFeatureFlag,
  getGroupMetrics,
  getMeta,
  getPrice,
  getWorkGroups,
  getWorkspaceOrgQuota,
  pauseWorkSpace,
  refreshWorkSpace,
  renameDatabase,
  renameWorkGroup,
  resumeWorkSpace,
  updateWorkSpace,
} from '@/pages/workgroup/api';
import {
  DatabaseT,
  getWorkspaceTypePrice,
  Pricing,
  FeatureFlags,
  UpdateWorkspaceRequest,
  WorkGroupT,
  WorkSpaceMeta,
  WorkSpaceOrgQuota,
  WorkspaceT,
  GroupMetrics,
} from '@/pages/workgroup/type';
import { AxiosError } from 'axios';
import { useMemo } from 'react';
import { UseQueryOptions, useMutation, useQuery, useQueryClient } from 'react-query';

export function useQueryGetPricing() {
  return useQuery<Result<Pricing>, AxiosError>(['resource-pricing'], getPrice);
}

export function useQueryGetOrgQuota() {
  return useQuery<Result<WorkSpaceOrgQuota>, AxiosError>(['workspaceQuota'], getWorkspaceOrgQuota);
}

export function useQueryGetFeatureFlag() {
  return useQuery<
    Result<
      {
        feature_flag: FeatureFlags;
        org_id: string;
      }[]
    >,
    AxiosError
  >(['feature-float'], getFeatureFlag);
}

export function useQueryGetGroups(
  options?: Omit<UseQueryOptions<Result<WorkGroupT[]>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(['groups'], getWorkGroups, options);
}

export function useMutationRenameWorkGroup() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; workgroup_name: string }>(
    ({ group_id, workgroup_name }) => {
      return renameWorkGroup(group_id, workgroup_name);
    },
    {
      onSuccess: async (data, { group_id, workgroup_name }) => {
        queryClient.invalidateQueries(['groups']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              name: workgroup_name,
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationUpdateWorkspace() {
  const queryClient = useQueryClient();
  const { isAdminDashboardPage } = useWorkspaceContext();

  return useMutation<
    Result<WorkspaceT>,
    AxiosError,
    { group_id: string; space_id: string; org_id?: string; data: UpdateWorkspaceRequest }
  >(
    ({ group_id, space_id, org_id, data }) => {
      return updateWorkSpace(group_id, space_id, data, isAdminDashboardPage, org_id);
    },
    {
      onSuccess: async (_, { group_id, space_id, org_id, data }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group', group_id]);
        if (isAdminDashboardPage) {
          queryClient.invalidateQueries(['admin', 'workspace', 'detail', org_id, group_id, space_id]);
        }

        if (data.workspace_name) {
          queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
            if (!oldData?.Result) {
              return oldData;
            }
            let newData = {
              ...oldData,
              Result: {
                ...oldData.Result,
                workspaces: oldData.Result.workspaces.map((w) =>
                  w.workspace_id !== space_id
                    ? w
                    : ({
                        ...w,
                        name: data.workspace_name,
                      } as WorkspaceT)
                ),
              },
            };
            return newData;
          });
        }
      },
    }
  );
}

export function useMutationDeleteWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return deleteWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['solutions', group_id]);
        queryClient.invalidateQueries(['quick_insights', group_id]);
        queryClient.invalidateQueries(['workspaceQuota']);
      },
    }
  );
}

export function useMutationPauseWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return pauseWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.invalidateQueries(['group', group_id]);
        queryClient.invalidateQueries(['groups']);

        // update workspace status to 'Pending'
        queryClient.setQueryData<Result<WorkGroupT[]> | undefined>(['groups'], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: oldData.Result.map((group) =>
              group.workgroup_id !== group_id
                ? group
                : {
                    ...group,
                    workspaces: group.workspaces.map((w) =>
                      w.workspace_id !== space_id
                        ? w
                        : ({
                            ...w,
                            status: 'Pending',
                          } as WorkspaceT)
                    ),
                  }
            ),
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationResumeWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return resumeWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id }) => {
        queryClient.invalidateQueries(['group', group_id]);
        queryClient.invalidateQueries(['groups']);
      },
    }
  );
}

export function useMutationRefreshWorkspace() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; space_id: string }>(
    ({ group_id, space_id }) => {
      return refreshWorkSpace(group_id, space_id);
    },
    {
      onSuccess: async (_, { group_id, space_id }) => {
        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              workspaces: oldData.Result.workspaces.map((w) =>
                w.workspace_id !== space_id
                  ? w
                  : ({
                      ...w,
                      refresh_status: 'Exporting',
                    } as WorkspaceT)
              ),
            },
          };

          queryClient.invalidateQueries(['group', group_id]);
          return newData;
        });
      },
    }
  );
}

export function useMutationRenameDatabase() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; db_id: string; database_name: string }>(
    ({ group_id, db_id, database_name }) => {
      return renameDatabase(group_id, db_id, database_name);
    },
    {
      onSuccess: async (_, { group_id, db_id, database_name }) => {
        queryClient.invalidateQueries(['groups']);
        queryClient.invalidateQueries(['group', group_id]);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              tg_databases: oldData.Result.tg_databases.map((d) =>
                d.database_id !== db_id
                  ? d
                  : ({
                      ...d,
                      name: database_name,
                    } as DatabaseT)
              ),
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useMutationDeleteDatabase() {
  const queryClient = useQueryClient();

  return useMutation<Result<void>, AxiosError, { group_id: string; db_id: string }>(
    ({ group_id, db_id }) => {
      return deleteDatabase(group_id, db_id);
    },
    {
      onSuccess: async (_, { group_id, db_id }) => {
        queryClient.invalidateQueries(['groups']);

        queryClient.setQueryData<Result<WorkGroupT> | undefined>(['group', group_id], (oldData) => {
          if (!oldData?.Result) {
            return oldData;
          }
          let newData = {
            ...oldData,
            Result: {
              ...oldData.Result,
              tg_databases: oldData.Result.tg_databases.filter((d) => d.database_id !== db_id),
            },
          };
          return newData;
        });
      },
    }
  );
}

export function useCloudProviders(filterFn?: (cp: CloudProviderT) => boolean) {
  const publicCPsQ = useQueryPublicCPs();
  const privateCpsQ = useQueryCPs();
  const loading = publicCPsQ.isLoading || privateCpsQ.isLoading;

  const cps: CloudProviderT[] = useMemo(() => {
    const cps = privateCpsQ.data?.Result || [];
    const publicCPs = (publicCPsQ.data?.Result || []).map((provider) => ({
      ...provider,
      type: 'public',
    }));
    const result = [...cps, ...publicCPs].filter(filterFn || (() => true)).sort((cp1, cp2) => {
      if (isCPDisabled(cp1) && !isCPDisabled(cp2)) {
        return 1;
      } else if (!isCPDisabled(cp1) && isCPDisabled(cp2)) {
        return -1;
      } else {
        return 0;
      }
    });

    const aws = publicCPs.find((cp) => cp.platform === 'AWS')!;
    const azure: CloudProviderT = {
      ...aws,
      id: 'azure',
      platform: 'Azure',
    };
    const gcp: CloudProviderT = {
      ...aws,
      id: 'gcp',
      platform: 'GCP',
    };
    return [...result, azure, gcp];
  }, [privateCpsQ.data?.Result, publicCPsQ.data?.Result, filterFn]);

  return { cps, isCpLoading: loading };
}

// calculate the price of the workspace based on the [platform, region, cloud_provider_id]
// 1 get the price/cloud provider from the workspace context
// 2 calculate the cloud provider type (TGCloud or BYOC) based on the cloud_provider_id
export function useQueryWorkspaceMeta(platform: string, region: string, cloud_provider_id: string) {
  const { price, cps } = useWorkspaceContext();

  const { data, isLoading } = useQuery<Result<WorkSpaceMeta>, AxiosError>(['workspaceMeta'], getMeta);

  const result = useMemo(() => {
    if (data?.Result && platform && region && cloud_provider_id && price && cps.length > 0) {
      let { tgVersions, workspaceTypes, regions } = data.Result;
      const cloud_provider = cps.find((cp) => cp.id === cloud_provider_id)?.type === 'public' ? 'TGCloud' : 'BYOC';

      workspaceTypes = workspaceTypes.map((workspaceType) => {
        const cost_per_hour = getWorkspaceTypePrice(
          price,
          workspaceType.typeName,
          false,
          platform,
          region,
          cloud_provider
        );

        const cost_per_hour_with_ha = getWorkspaceTypePrice(
          price,
          workspaceType.typeName,
          true,
          platform,
          region,
          cloud_provider
        );

        return {
          ...workspaceType,
          cost_per_hour,
          cost_per_hour_with_ha,
        };
      });

      return {
        tgVersions,
        workspaceTypes,
        regions,
      };
    } else {
      return undefined;
    }
  }, [data?.Result, platform, region, cloud_provider_id, price, cps]);

  if (!price || cps.length === 0) {
    return {
      isLoading: true,
      data: undefined,
    };
  }

  if (isLoading) {
    return {
      isLoading: true,
      data: undefined,
    };
  }

  return {
    isLoading: false,
    data: result,
  };
}

export function useQueryGetGroupMetrics(
  group_id: string,
  options?: Omit<UseQueryOptions<Result<GroupMetrics>, AxiosError>, 'queryKey' | 'queryFn'>
) {
  return useQuery(
    ['group_metrics', group_id],
    () => {
      return getGroupMetrics(group_id);
    },
    options
  );
}
